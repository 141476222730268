body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 /* WE USE TO CUSTOMIZE SCROLLBAR */

::-webkit-scrollbar {
    width: 1em;
    height: 1em;
}

::-webkit-scrollbar-track {
    background: linear-gradient(#2FAFF6, #47BAEB, #DAFFA5,  #FF9397 , #FFA8FC);
    border-radius: 100vw;
    margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
    background: #DFED57;
    border: 0.25em solid #DFED57;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
    border: 0.25em solid #DFED57;
}

 /* WE USE TO CUSTOMIZE SCROLLBAR */